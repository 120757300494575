import React, { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NoxioNcImage from "shared/NcImage/NoxioNcImage";
import ModalPhotos from "./ModalPhotos";
import { useLocation, useParams } from "react-router-dom";
import { NoxioPropertyDataType } from "data/types";

import "moment-timezone";
import propertyPrice from "utils/propertyPrice";
import NoxioService from "services/NoxioService";
import { RouteParams } from "routers/types";
import amenities from "data/amenities";
import minimalPrice from "utils/minimalPrice";
import maximalPrice from "utils/maximalPrice";
import NoxioButtonReserve from "./NoxioButtonReserve";
import { setUrlParameter } from "utils/setUrlParameter";
import NoxioStayDatesRangeInput from "components/HeroSearchForm/NoxioStayDatesRangeInput";
import isDayBlocked from "utils/isDayBlocked";
import NoxioGuestsInput from "components/HeroSearchForm/NoxioGuestsInput";
import Badge from "shared/Badge/Badge";
import { AppContext, AppContextInterface } from "context/appContext";
import propertyPhotoPath from "utils/propertyPhotoPath";
import getGoogleMapApiKey from "utils/getGoogleMapApiKey";
import getDayPrice from "utils/getDayPrice";

export interface NoxioStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}
interface LocationState {
  property: NoxioPropertyDataType;
  isSelectedDates?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
}

const NoxioStayDetailPage: FC<NoxioStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const { bookingEngineInfo, setBookingEngineInfo } = React.useContext(
    AppContext
  ) as AppContextInterface;

  const searchParams = new URLSearchParams(window.location.search);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: searchParams.get("startDate")
      ? moment.utc(searchParams.get("startDate"), "YYYY-MM-DD")
      : null,
    endDate: searchParams.get("endDate")
      ? moment.utc(searchParams.get("endDate"), "YYYY-MM-DD")
      : null,
  });

  const [guestAdults, setGuestAdults] = useState<number>(
    parseInt(searchParams.get("guestAdults") ?? "2")
  );
  const [guestChildren, setGuestChildren] = useState(
    parseInt(searchParams.get("guestChildren") ?? "0")
  );
  const [guestInfants, setGuestInfants] = useState(
    parseInt(searchParams.get("guestInfants") ?? "0")
  );

  const [nights, setNights] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [currentProperty, setCurrentProperty] = useState<NoxioPropertyDataType | null>(
    null
  );

  const [PHOTOS, setPHOTOS] = useState<string[]>([]);
  const [blockedDates, setBlockedDates] = useState<string[]>();
  const [isHiddenDescriprion, setIsHiddenDescriprion] = useState(true);

  const data = useLocation<LocationState>();
  const noxioService = new NoxioService();
  const params = useParams<RouteParams>();

  const disallowBookingInfo = bookingEngineInfo?.disallowBookingInfo;
  const cancellationPolicyInfo = bookingEngineInfo?.cancellationPolicyInfo;

  useEffect(() => {
    const getProperty = async () => {
      const property = await noxioService.getPropertyByUrlName(
        params.urlName,
        params.account
      );
      setCurrentProperty(property);
    };
    const getBlockedDates = async (urlName: string) => {
      const newBlockedDates = await noxioService.getBlockedDates(urlName, params.account);
      setBlockedDates(newBlockedDates);
    };

    if (!bookingEngineInfo) {
      const getBookingEngineInfo = async () => {
        const newBookingEngineInfo = await noxioService.getBookingEngineInfo(
          params.account
        );
        setBookingEngineInfo(newBookingEngineInfo);
      };

      getBookingEngineInfo();
    }

    getBlockedDates(params.urlName);

    if (data.state && data.state.property) {
      const property: NoxioPropertyDataType = data.state.property;
      setCurrentProperty(property);
      if (data.state.isSelectedDates && data.state.startDate && data.state.endDate) {
        setSelectedDate({ startDate: data.state.startDate, endDate: data.state.endDate });
      }
    } else {
      getProperty();
    }
  }, [data]);

  useEffect(() => {
    if (currentProperty) {
      const propertyPhotoLinks = currentProperty.media.map((m) =>
        m.url ? m.url : `${propertyPhotoPath()}${m._id}`
      );

      setPHOTOS(propertyPhotoLinks);
    }
  }, [currentProperty]);

  useEffect(() => {
    const currentStartDate = selectedDate.startDate?.clone();

    const currentEndDate = selectedDate.endDate?.clone();
    setNights(currentEndDate ? currentEndDate?.diff(currentStartDate, "days") : 0);

    if (currentStartDate && currentEndDate && currentProperty) {
      const newTotalPrice = propertyPrice(
        currentStartDate,
        currentEndDate,
        currentProperty
      );
      setTotalPrice(newTotalPrice);
    }
  }, [selectedDate, currentProperty]);

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const guestChangeHandler = (data: {
    guestAdults?: number;
    guestChildren?: number;
    guestInfants?: number;
  }) => {
    if (data.guestAdults !== undefined && guestAdults !== data.guestAdults) {
      setGuestAdults(data.guestAdults);
      setUrlParameter("guestAdults", data.guestAdults.toString());
    }
    if (data.guestChildren !== undefined && guestChildren !== data.guestChildren) {
      setGuestChildren(data.guestChildren);
      setUrlParameter("guestChildren", data.guestChildren.toString());
    }

    if (data.guestInfants !== undefined && guestInfants !== data.guestInfants) {
      setGuestInfants(data.guestInfants);
      setUrlParameter("guestInfants", data.guestInfants.toString());
    }
  };

  const getFullDescHeight = () => {
    let height = 0;

    const children = document.getElementById("full-desc-wrapper")?.children;
    if (children) {
      const childrenArr = Array.from(children);
      height = childrenArr.reduce(
        (partialSum, el) => partialSum + (el as HTMLElement).offsetHeight,
        0
      );
    }

    return height;
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={currentProperty?.type[0]?.name} />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {currentProperty?.publicName}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {" "}
              {`${currentProperty?.address.address1}, ${currentProperty?.address.country}`}
            </span>
          </span>
        </div>

        {/* 4 */}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {currentProperty?.sleeps}{" "}
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {currentProperty?.beds} <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {currentProperty?.bathrooms}{" "}
              <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              {currentProperty?.bedrooms}{" "}
              <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    const className = `text-neutral-6000 dark:text-neutral-300 overflow-hidden stay-fullDescription ${
      isHiddenDescriprion ? "description-hidden" : ""
    }`;

    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div
          id="full-desc-wrapper"
          className={className}
          dangerouslySetInnerHTML={{
            __html:
              currentProperty?.fullDescription?.replace(
                "font-family: Roboto, Helvetica, Arial, sans-serif;",
                ""
              ) ?? "",
          }}
        ></div>

        {getFullDescHeight() > 100 && (
          <button
            className="text-primary-500 font-bold uppercase text-xs"
            type="button"
            onClick={() => setIsHiddenDescriprion((prev) => !prev)}
          >
            {isHiddenDescriprion ? "Show more..." : "Show less..."}
          </button>
        )}
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {currentProperty?.amenities
            .filter((a, i) => i < 12)
            .map((item) => {
              let amenity = amenities.filter((x) => x.name === item.name)[0];
              if (amenity)
                return (
                  <div key={amenity.name} className="flex items-center space-x-3">
                    <i className={`text-3xl las ${amenity.icon}`}></i>
                    <span className=" ">{amenity.name}</span>
                  </div>
                );
              return null;
            })}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {currentProperty?.amenities
                      .filter((_, i) => i < 1212)
                      .map((item) => {
                        let amenity = amenities.filter((x) => x.name === item.name)[0];
                        if (amenity)
                          return (
                            <div
                              key={amenity.name}
                              className="flex items-center py-6 space-x-8"
                            >
                              <i
                                className={`text-4xl text-neutral-6000 las ${amenity.icon}`}
                              ></i>
                              <span>{amenity.name}</span>
                            </div>
                          );
                        return null;
                      })}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum price</span>
              <span>&euro;{minimalPrice(currentProperty)}</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Maximum price</span>
              <span>&euro;{maximalPrice(currentProperty)}</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>
                {currentProperty?.minStay ?? 1} night
                {currentProperty?.minStay && currentProperty?.minStay > 1 ? "s" : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="nc-SetYourAvailabilityData -mx-4 sm:mx-auto xl:mx-[-22px]">
            <DayPickerRangeController
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onDatesChange={(date) => {
                if (
                  blockedDates &&
                  -blockedDates.some((blockedDate) => {
                    return (
                      date.startDate &&
                      date.endDate &&
                      moment(blockedDate).isBetween(date.startDate, date.endDate)
                    );
                  })
                ) {
                  return;
                } else {
                  setSelectedDate(date);
                  if (date.startDate)
                    setUrlParameter("startDate", date.startDate.format("YYYY-MM-DD"));
                  if (date.endDate)
                    setUrlParameter("endDate", date.endDate.format("YYYY-MM-DD"));
                }
              }}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) =>
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              initialVisibleMonth={null}
              numberOfMonths={windowSize.width < 1280 ? 1 : 2}
              daySize={getDaySize()}
              isOutsideRange={(day) =>
                day.clone().startOf("day").isBefore(moment().startOf("day"))
              }
              hideKeyboardShortcutsPanel={false}
              isDayBlocked={(day) =>
                isDayBlocked(
                  day,
                  blockedDates,
                  disallowBookingInfo &&
                    disallowBookingInfo.checkinInFuture &&
                    disallowBookingInfo.checkinInFutureValue
                    ? disallowBookingInfo.checkinInFutureValue
                    : null,
                  disallowBookingInfo?.dateBeforeCheckin &&
                    disallowBookingInfo.dateBeforeCheckinValue
                    ? disallowBookingInfo.dateBeforeCheckinValue
                    : null
                )
              }
              renderDayContents={(day) => {
                const cloneDay = day.clone();
                const standardPrice = currentProperty?.standardPrice ?? 0;

                const currentPrice = getDayPrice(currentProperty, cloneDay);

                const isBigger = currentPrice > standardPrice;
                const isSmaller = currentPrice < standardPrice;

                const className = `text-xs ${isBigger ? "text-red-400" : ""} ${
                  isSmaller ? "text-green-400" : ""
                }`;

                return (
                  <div>
                    <p>{day.date()}</p>
                    <p className={`CalendarDay_price_data ${className}`}>
                      &euro;
                      {currentPrice}
                    </p>
                  </div>
                );
              }}
              renderCalendarInfo={() => (
                <>
                  {disallowBookingInfo?.minStay && disallowBookingInfo?.minStayValue ? (
                    <div className="px-5 py-3">{`Minimum night - ${disallowBookingInfo.minStayValue}`}</div>
                  ) : null}
                  {disallowBookingInfo?.maxStay && disallowBookingInfo?.maxStayValue ? (
                    <div className="px-5 py-3">{`Maximum night - ${disallowBookingInfo.maxStayValue}`}</div>
                  ) : null}
                </>
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {currentProperty?.address.location}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: getGoogleMapApiKey(),
              }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={{
                lat: parseFloat(currentProperty?.address.latitude ?? "0"),
                lng: parseFloat(currentProperty?.address.longitude ?? "0"),
              }}
            >
              <LocationMarker
                lat={parseFloat(currentProperty?.address.latitude ?? "0")}
                lng={parseFloat(currentProperty?.address.longitude ?? "0")}
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          {cancellationPolicyInfo?.nonRefundable && (
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              Non Refundable (The guest can not cancel free of charge)
            </span>
          )}
          {cancellationPolicyInfo?.advanceNonRefundable && (
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              The Advance Payment is NOT REFUNDED to the guest in the event of
              cancellation.
            </span>
          )}
          {cancellationPolicyInfo?.cancellForFree && (
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {`Until ${cancellationPolicyInfo?.cancellDaysBeforeArrival} days before arrival the guest can cancel for free. 
              If the guest cancels ${cancellationPolicyInfo?.cancellDaysBeforeArrival} before arrival, the cancellation fee will be ${cancellationPolicyInfo?.cancellationFee}%`}
            </span>
          )}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and environment green
                and clean by not littering, not using stimulants and respecting people
                around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl" id="sidebar">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {selectedDate.startDate && selectedDate.endDate ? "" : "From "}
            &euro;
            {selectedDate.startDate && selectedDate.endDate
              ? (totalPrice / nights).toFixed(2)
              : minimalPrice(currentProperty).toFixed(2)}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <NoxioStayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => {
              setSelectedDate(date);
              if (date.startDate)
                setUrlParameter("startDate", date.startDate.format("YYYY-MM-DD"));
              if (date.endDate)
                setUrlParameter("endDate", date.endDate.format("YYYY-MM-DD"));
            }}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
            blockedDates={blockedDates}
            propertyStandardPrice={currentProperty?.standardPrice}
            propertyPrices={currentProperty?.ratePlan}
            isOnPropertyPage={true}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <NoxioGuestsInput
            fieldClassName="p-5"
            defaultValue={{
              guestAdults: guestAdults,
              guestChildren: guestChildren,
              guestInfants: guestInfants,
            }}
            onChange={guestChangeHandler}
            maximumGuests={currentProperty?.sleeps ?? 0}
            babyCribs={currentProperty?.babyCribs ?? 0}
          />
        </form>

        {/* SUM */}
        {totalPrice && nights ? (
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                &euro; {(totalPrice / nights).toFixed(2)} x {nights} nights
              </span>
              <span>&euro; {totalPrice}</span>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>&euro; {totalPrice}</span>
            </div>
          </div>
        ) : null}

        {/* SUBMIT */}
        <NoxioButtonReserve
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
          guestAdults={guestAdults}
          guestChildren={guestChildren}
          guestInfants={guestInfants}
          property={currentProperty}
          totalPrice={totalPrice}
          nights={nights}
          blockedDates={blockedDates || []}
        />
      </div>
    );
  };
  if (currentProperty) {
    return (
      <div
        className={`nc-ListingStayDetailPage  ${className}`}
        data-nc-id="ListingStayDetailPage"
      >
        {/* SINGLE HEADER */}
        <>
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 min-h-[100px] sm:min-h-[300px]">
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NoxioNcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={PHOTOS[0]}
                  prevImageHorizontal
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 3 ? "hidden sm:block" : ""
                  }`}
                >
                  <NoxioNcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item || ""}
                    prevImageHorizontal
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(index + 1)}
                  />
                </div>
              ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          {/* MODAL PHOTOS */}
          <ModalPhotos
            imgs={PHOTOS}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
          />
        </>

        {/* MAIn */}
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row  ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            {renderSection1()}
            {renderSection2()}
            {renderSection3()}
            {renderSection4()}
            {renderSectionCheckIndate()}
            {renderSection7()}
            {renderSection8()}
          </div>

          {/* SIDEBAR */}
          <div className="block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-24 mb-64">{renderSidebar()}</div>
          </div>
        </main>

        {/* STICKY FOOTER MOBILE */}
        {!isPreviewMode && (
          <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
            <div className="container flex items-center justify-between">
              <span className="text-2xl font-semibold">
                {selectedDate.startDate && selectedDate.endDate
                  ? (totalPrice / nights).toFixed(2)
                  : currentProperty?.standardPrice.toFixed(2)}
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                  /night
                </span>
              </span>

              <div className="w-1/2">
                <a
                  href="#sidebar"
                  type="button"
                  className="h-10 md:h-12 w-full rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50"
                >
                  Book now
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default NoxioStayDetailPage;
