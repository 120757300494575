import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "./LangDropdown";
import CurrencyDropdown from "./CurrencyDropdown";

export interface NoxioMainNavProps {
  isTop: boolean;
}

const NoxioMainNav: FC<NoxioMainNavProps> = ({ isTop }) => {
  return (
    <div
      className={`nc-MainNav1 nc-MainNav2 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          <div className="hidden sm:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <CurrencyDropdown />
            <LangDropdown />

            <div></div>
            <SwitchDarkMode />
            <div></div>
          </div>
          <div className="flex items-center space-x-4 xl:hidden">
            <MenuBar />
          </div>
        </div>
      </div>

      {/*<p className="ml-8 pb-2 text-red-300">
        Hello ! This site is not production ready yet. Also the name Noxio needs to be verified if we are allowed to use it. 
        This site is still in beta, please use it
        for testing only. Soon we will be ready. Thank you.
      </p>*/}
    </div>
  );
};

export default NoxioMainNav;
